/* Styling for the "aboutme" section */
.aboutme {
  font-family: "Roboto-Regular", sans-serif;
  margin-top: 80px;
  color: white; /* Text color */
  background-color: #282a36; /* Background color */
  padding: 10px;
}

/* Styling for the main heading */
.aboutme h1 {
  font-family: "RenogareSoft-Regular", sans-serif;
  text-align: center;
  margin-bottom: 20px;
}

.aboutme p {
  font-size: 20px;
  padding-bottom: 10px;
}

/* Styling for the navigation tabs */
.nav-pills .nav-link {
  font-family: "Roboto-Bold", sans-serif;
  border-radius: 0; /* Square tabs */
  color: white; /* Tab text color */
  font-size: 22px !important;
  margin: 5px;
}

/* Hover effect for the tabs */
.nav-pills .nav-link:hover {
  background-color: #fad027 !important; /* Tab text color on hover */
  color: white;
}

/* Styling for the active tab */
.nav-pills .nav-link.active {
  background-color: #ff7c4a !important; /* Background color of the active tab */
}

/* Styling for the tab content area */
.tab-content {
  border-radius: 0.5%; /* Square content area */
  background-color: #2a2c38;
  padding: 5px; /* Padding inside the content area */
}

/* Transition effect for smooth color change */
.nav-pills .nav-link {
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out !important;
}
