/* Base style for home section */
.home {
  position: relative;
  min-height: 100vh;
  color: white;
  text-align: center;
  overflow: hidden;
}

/* Container for the slideshow, ensuring it takes the full viewport size */
.slideshow-container {
  position: relative;
  max-width: 100%;
  height: 100vh;
  margin: auto;
}

/* Gradient overlay to improve text readability on slides */
.slideshow-container::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  z-index: 3;
}

/* Style for each slide, initially hidden with opacity */
.mySlides {
  position: absolute;
  width: 100%;
  height: 100vh;
  opacity: 0;
  z-index: 1;
  transition: opacity 3s ease-in-out;
}

/* Image responsiveness and covering the entire slide */
.mySlides img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

/* Styling for the active slide */
.mySlides.active {
  opacity: 1;
  z-index: 2;
  transition: opacity 3s ease-in-out;
}

/* Positioning and styling for the text overlay */
.welcome-text {
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
}

/* Positioning and styling for the text overlay */
.home-text {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  font-family: "RenogareSoft-Regular", sans-serif;
}

/* Applying gradient color to match my logo */
.gradient-text {
  background: linear-gradient(90deg, #ff544f, #fad027);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}
