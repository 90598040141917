body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Customizing the Navbar appearance */
.navbar {
  background-color: #282a36 !important;
  padding: 0 1rem !important;
  font-family: "Roboto-Regular", sans-serif;
}

/* Adjusting the brand image size */
.navbar-brand img {
  height: auto;
  width: auto;
  max-height: 40px;
}

/* Style adjustments for navigation links */
.nav-link {
  font-size: 1.2rem !important;
  padding: 0.5rem !important;
  transition: color 0.3s ease-in-out;
}

/* Ensuring social icons display in a row */
.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Custom style for social media nav links */
.navbar .nav-link {
  padding: 0 !important;
  margin: 0 10px;
}

/* Style for inline display of nav links in collapsed navbar */
.navbar-collapse .nav-link {
  display: inline-block !important;
}

/* FontAwesome icons style */
.nav-link .fa {
  font-size: 20px;
}

/* LinkedIn icon hover effect */
.nav-link:hover .fa-linkedin-square {
  color: #0077b5 !important;
}

/* GitHub icon hover effect */
.nav-link:hover .fa-github {
  color: #7dbbe6 !important;
}

/* Instagram icon hover effect */
.nav-link:hover .fa-instagram {
  background: linear-gradient(
    45deg,
    #405de6,
    #5851db,
    #833ab4,
    #c13584,
    #e1306c,
    #fd1d1d
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent !important;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: #1e2125 !important;
  padding: 20px 0;
  font-family: "Roboto-Regular", sans-serif;
  min-height: 15vh;
}

.footer-content {
  width: 100%;
  max-width: 1100px;
  padding: 0 20px;
  box-sizing: border-box;
}

.footer-content p {
  color: rgb(197, 197, 197);
  margin: 5px 0;
  font-size: 10px;
  text-align: center;
}

/* Base style for home section */
.home {
  position: relative;
  min-height: 100vh;
  color: white;
  text-align: center;
  overflow: hidden;
}

/* Container for the slideshow, ensuring it takes the full viewport size */
.slideshow-container {
  position: relative;
  max-width: 100%;
  height: 100vh;
  margin: auto;
}

/* Gradient overlay to improve text readability on slides */
.slideshow-container::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  z-index: 3;
}

/* Style for each slide, initially hidden with opacity */
.mySlides {
  position: absolute;
  width: 100%;
  height: 100vh;
  opacity: 0;
  z-index: 1;
  transition: opacity 3s ease-in-out;
}

/* Image responsiveness and covering the entire slide */
.mySlides img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

/* Styling for the active slide */
.mySlides.active {
  opacity: 1;
  z-index: 2;
  transition: opacity 3s ease-in-out;
}

/* Positioning and styling for the text overlay */
.welcome-text {
  position: absolute;
  bottom: 20%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 4;
}

/* Positioning and styling for the text overlay */
.home-text {
  position: absolute;
  bottom: 10%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 4;
  font-family: "RenogareSoft-Regular", sans-serif;
}

/* Applying gradient color to match my logo */
.gradient-text {
  background: linear-gradient(90deg, #ff544f, #fad027);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.projects {
  margin-top: 80px;
  color: white; /* Text color */
  background-color: #282a32; /* Added background color */
  padding: 10px;
  font-family: "Roboto-Regular", sans-serif;
}

.projects h1 {
  font-family: "RenogareSoft-Regular", sans-serif;
  text-align: center;
  margin-bottom: 20px;
}

.projects p {
  font-size: 20px;
  padding-bottom: 10px;
}

/* Styling the project cards */
.project-card {
  background-color: #23272e !important;
  border: none !important;
  transition: box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  margin-left: 10px;
  margin-right: 10px;
}

.project-link {
  text-decoration: none;
  color: inherit;
}

.project-link:hover {
  text-decoration: none;
}

.project-card:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.project-card .card-subtitle {
  font-family: "Roboto-Bold", sans-serif;
}

.project-card .card-subtitle,
.project-card .card-text {
  color: white !important;
  font-size: 16px;
}

.project-card .card-title {
  color: #fad233;
  font-family: "Roboto-Bold", sans-serif;
  font-size: 22px;
}

.project-card .card-text {
  text-align: left;
}

.technologies {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* Style for each technology tag */
.tech-tag {
  display: inline-block;
  background-color: #282a36;
  color: white !important;
  border-radius: 4px;
  padding: 5px 10px;
  margin: 2px 5px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  vertical-align: middle;
}

/* Styles for the icons within the technology tags */
.tech-tag svg {
  margin-right: 5px;
  vertical-align: middle;
}

/* Style for View Project Button */
.project-card .btn {
  background-color: #fad027;
  color: white;
  font-family: "Roboto-Bold", sans-serif;
  border-color: transparent;
}

/* Hover and active states for button */
.project-card .btn:hover {
  background-color: #ffa041;
  color: white;
  border-color: transparent;
}
.project-card .btn:active {
  background-color: #ff7c4a !important;
  color: white;
  border-color: transparent !important;
}

/* General styling for the entire Testimonials section */
.testimonials {
  margin-top: 80px;
  padding: 10px;
  color: white;
  font-family: "Roboto-Regular", sans-serif;
}

/* Styling for the container in the Testimonials section */
.testimonials.container {
  background-color: #282a36 !important;
}

/* Styling for the headers in the Testimonials section */
.testimonials h1,
.testimonials h5 {
  font-family: "RenogareSoft-Regular", sans-serif;
  text-align: center;
  margin-bottom: 20px;
}

.testimonials p {
  font-size: 20px;
  padding-bottom: 10px;
}

/* Style for SweetAlert popup text */
.swal2-popup {
  color: #fff !important;
}

/* Padding for individual slick slides */
.slick-slide {
  padding: 10px;
}

/* Positioning for slick next arrow */
.slick-next {
  right: 0px !important;
  z-index: 1;
}

/* Positioning for slick previous arrow */
.slick-prev {
  left: 0px !important;
  z-index: 1;
}

/* Styling for individual testimonial cards */
.testimonial-card {
  background-color: #212529 !important;
  border-color: transparent !important;
  color: white !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
}

/* Hover effect for testimonial cards */
.testimonial-card:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Styling for the title inside the testimonial card */
.testimonial-card .card-title {
  color: #fad027;
  font-family: "Roboto-Bold", sans-serif;
}

.testimonial-card .card-text {
  font-size: 16px;
}
/* Styling for the testimonial submission form */
.testimonial-form {
  padding: 20px;
  background-color: #212529;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

/* Common styling for input and textarea fields in the testimonial form */
.testimonial-form input,
.testimonial-form textarea {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 3px;
  font-size: 0.9em;
}

/* Styling for the submit button in the testimonial form */
.testimonial-form button {
  background-color: #fad027;
  color: white;
  cursor: pointer;
  border: none;
  font-family: "Roboto-Bold", sans-serif;
}

/* Hover effect for the submit button */
.testimonial-form button:hover {
  background-color: #ffa041;
}

.testimonial-form button:active {
  background-color: #ff7c4a !important;
}

/* Responsive design for screens smaller than 992px */
@media (max-width: 992px) {
  /* Add margin to the bottom of cards and forms for better spacing */
  .testimonial-card,
  .testimonial-form {
    margin-bottom: 20px;
  }
}

/* General styling for the Contact section */
.contact {
  padding: 50px 0;
  color: white;
  font-family: "Roboto-Regular", sans-serif;
  background-color: #282a32;
}

/* Override container background color */
.contact.container {
  background-color: #282a36 !important;
}

/* Heading styles */
.contact h1 {
  font-family: "RenogareSoft-Regular", sans-serif;
  text-align: center;
  margin-bottom: 20px;
}

/* Paragraph styles */
.contact p {
  font-size: 20px;
  padding-bottom: 10px;
}

/* Style for SweetAlert popup text */
.swal2-popup {
  color: #fff !important;
}

/* Contact form styling */
.contact-form {
  padding: 20px;
  background-color: #212529;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

/* Input fields styling */
.contact-form input,
.contact-form textarea {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 3px;
  font-size: 0.9em;
}

/* Button styles */
.contact-form button {
  background-color: #fad027;
  color: white;
  cursor: pointer;
  border: none;
  font-family: "Roboto-Bold", sans-serif;
}

/* Hover and active states for button */
.contact-form button:hover {
  background-color: #ffa041;
}
.contact-form button:active {
  background-color: #ff7c4a !important;
}

/* Profile image styling */
.profile-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
}

/* Social media icons layout */
.social-media-icons a {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}

/* Download Resume Button */
.btn-download-resume {
  background-color: #fad027 !important;
  color: white !important;
  font-family: "Roboto-Bold", sans-serif !important;
}

/* Responsive Design */
@media (max-width: 992px) {
  .contact-form {
    margin-bottom: 20px;
  }
}

/* Styling for the "aboutme" section */
.aboutme {
  font-family: "Roboto-Regular", sans-serif;
  margin-top: 80px;
  color: white; /* Text color */
  background-color: #282a36; /* Background color */
  padding: 10px;
}

/* Styling for the main heading */
.aboutme h1 {
  font-family: "RenogareSoft-Regular", sans-serif;
  text-align: center;
  margin-bottom: 20px;
}

.aboutme p {
  font-size: 20px;
  padding-bottom: 10px;
}

/* Styling for the navigation tabs */
.nav-pills .nav-link {
  font-family: "Roboto-Bold", sans-serif;
  border-radius: 0; /* Square tabs */
  color: white; /* Tab text color */
  font-size: 22px !important;
  margin: 5px;
}

/* Hover effect for the tabs */
.nav-pills .nav-link:hover {
  background-color: #fad027 !important; /* Tab text color on hover */
  color: white;
}

/* Styling for the active tab */
.nav-pills .nav-link.active {
  background-color: #ff7c4a !important; /* Background color of the active tab */
}

/* Styling for the tab content area */
.tab-content {
  border-radius: 0.5%; /* Square content area */
  background-color: #2a2c38;
  padding: 5px; /* Padding inside the content area */
}

/* Transition effect for smooth color change */
.nav-pills .nav-link {
  transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out !important;
}

/* Applying general styles to the employment section */
.employment {
  background-color: transparent; /* Applying a background color for contrast */
}

/* Styles for the vertical timeline elements */
.vertical-timeline-element-title {
  color: #fad233 !important; /* Setting the color for the titles in the timeline */
  font-size: 22px !important;
  font-family: "Roboto-Bold", sans-serif;
}

.vertical-timeline-element-subtitle {
  color: white; /* Setting the color for the subtitles */
  font-size: 16px !important;
  font-family: "Roboto-Regular", sans-serif;
}

.vertical-timeline-element-date {
  font-size: 20px !important;
  color: #fad233 !important; /* Setting the color for the date in the timeline */
  font-family: "Roboto-Bold", sans-serif;
}

/* Additional styles to adjust the spacing and layout */
.vertical-timeline-element-content {
  text-align: left;
  padding-left: 20px !important; /* Adding padding to the left to separate the content from the border */
  background-color: #23272e !important; /* Applying a background color for contrast */
}

/* Styling the degree cards */
.degree-card {
  background-color: #23272e !important; /* Set the card background color */
  border: none !important; /* Remove the default border */
  transition: box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; /* Add transition for smooth transform and shadow effects */
}

/* Change the card hover effect */
.degree-card:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px); /* Lift the card up slightly on hover */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Apply shadow to make the card pop */
}

/* Styling the card title */
.degree-card .card-title {
  color: #fad233; /* Change the title color */
  font-size: 22px;
  font-family: "Roboto-Bold", sans-serif;
}

/* Styling the card subtitle */
.degree-card .card-subtitle {
  margin-bottom: 15px; /* Add margin at the bottom for spacing */
  color: white !important; /* Make the text color white for better contrast */
  font-family: "Roboto-Bold", sans-serif;
}

/* Styling the card text */
.degree-card .card-text {
  color: white; /* Make the text color white for better contrast */
  font-family: "Roboto-Regular", sans-serif;
  font-size: 16px;
  margin-bottom: 0px !important; /* Add margin at the bottom for spacing */
}

/* Styling the field of study text */
.degree-card .card-text small {
  display: block; /* Make it block to appear on a new line */
  margin-bottom: 10px; /* Add margin at the bottom for spacing */
  color: #fad233; /* Change the text color */
  font-family: "Roboto-Bold", sans-serif;
  font-size: 16px;
}

/* Styling the certificate cards */
.certificate-card {
  background-color: #23272e !important; /* Changing the card background color */
  border: none !important; /* Removing the default border */
  transition: box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; /* Adding transition for smooth transform and shadow effects */
}

/* Changing the card hover effect */
.certificate-card:hover {
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px); /* Lifting the card up slightly on hover */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2); /* Applying shadow to make the card pop */
}

/* Styling the card title */
.certificate-card .card-title {
  color: #fad233; /* Changing the title color */
  font-size: 22px;
  font-family: "Roboto-Bold", sans-serif;
}

/* Styling the card subtitle */
.certificate-card .card-subtitle {
  margin-bottom: 15px; /* Adding margin at the bottom for spacing */
  color: white !important; /* Making the text color white for better contrast */
  font-family: "Roboto-Bold", sans-serif;
  font-size: 16px;
}

/* Styling the card text */
.certificate-card .card-text {
  color: white; /* Making the text color white for better contrast */
  font-family: "Roboto-Regular", sans-serif;
  font-size: 16px;
}

/* Remove default styles for the anchor tag */
.certificate-link {
  text-decoration: none; /* Removing the underline */
  color: inherit; /* Inheriting the color from the parent element */
  font-family: "Roboto-Bold", sans-serif;
}

/* Applying hover effect to the anchor tag */
.certificate-link:hover {
  text-decoration: none; /* Removing the underline on hover */
}

.not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 85vh;
  color: white;
  text-align: center;
  background-color: #23272f;
}

/* Applying gradient color */
.gradient-text {
  background: linear-gradient(90deg, #ff544f, #fad027);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

/* Font styling and centering text */
.not-found-text {
  font-family: "RenogareSoft-Regular", sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/*Dracula Theme Color*/

body {
  background-color: #282a36 !important;
  color: #f8f8f2 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #f8f8f2 !important;
}

a {
  color: #8be9fd;
}

a:hover {
  color: #ff7c4a;
}

@font-face {
  font-family: "RenogareSoft-Regular";
  src: url(/static/media/RenogareSoft-Regular.0d4f5b8c.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Bold";
  src: url(/static/media/Roboto-Bold.36b5bab5.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto-Regular";
  src: url(/static/media/Roboto-Regular.5673da52.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

